import React, { useState } from "react";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import FlexiForm, { Fields } from "@bit/flexibill.flexi.flexi-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useMemo } from "react";
import { StyledChip } from "../../components/FormFields/AgreementSelectField";

const CustomerSection = ({ values, index, customer }) => {
  const contactFields = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          name: `customer.${index}.contact.name`,
          required: true,
          label: "Neve"
        }
      ],
      [
        {
          fieldType: "text",
          name: `customer.${index}.contact.title`,
          required: true,
          label: "Beosztása"
        }
      ],
      [
        {
          fieldType: "text",
          name: `customer.${index}.contact.telephone`,
          required: true,
          label: "Telefonszáma"
        }
      ],
      [
        {
          fieldType: "text",
          name: `customer.${index}.contact.electronicMail`,
          required: true,
          label: "E-mail címe"
        }
      ]
    ];
  }, []);
  const customerFields = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          name: `customer.${index}.serviceDescription`,
          required: true,

          label: "Milyen tevékenységet végzel a vevő felé?"
        }
      ],
      [
        {
          fieldType: "text",
          name: `customer.${index}.serviceSince`,
          required: true,

          label: "Mióta állsz kapcsolatban a vevővel?"
        }
      ]
    ];
  }, []);
  return (
    <>
      <Typography
        variant={"h6"}
        style={{ paddingTop: "16px", paddingBottom: "32px" }}
      >
        VEVŐI KAPCSOLATTARTÓ ADATAI
      </Typography>
      <Fields config={contactFields} />
      <Typography
        variant={"h6"}
        style={{ paddingTop: "16px", paddingBottom: "32px" }}
      >
        SZÁMLA VISSZAIGAZOLÁSÁSRA JOGOSULT KAPCSOLATTARTÓ ADATAI
      </Typography>
      <Fields
        config={[
          [
            {
              fieldType: "checkbox",
              name: `customer.${index}.sameContactForInvoiceConfirmation`,
              label: "Megegyezik a kapcsolattartó adataival"
            }
          ],
          [
            {
              fieldType: "text",
              name: `customer.${index}.contactForInvoiceConfirmation.name`,
              label: "Neve",
              disabled:
                values?.customer?.[index]?.sameContactForInvoiceConfirmation
            }
          ],
          [
            {
              fieldType: "text",
              name: `customer.${index}.contactForInvoiceConfirmation.telephone`,
              label: "Telefonszáma",
              disabled:
                values?.customer?.[index]?.sameContactForInvoiceConfirmation
            }
          ],
          [
            {
              fieldType: "email",
              name: `customer.${index}.contactForInvoiceConfirmation.electronicMail`,
              label: "E-mail címe",
              disabled:
                values?.customer?.[index]?.sameContactForInvoiceConfirmation
            }
          ]
        ]}
      />
      <Typography
        variant={"h6"}
        style={{ paddingTop: "16px", paddingBottom: "32px" }}
      >
        TOVÁBBI ADATOK
      </Typography>
      <Fields config={customerFields} />
      <Typography variant={"h6"} style={{ paddingTop: "16px" }}>
        Volt-e az elmúlt 1 évben számlázás a vevő felé?*
      </Typography>
      <Fields
        config={[
          [
            {
              fieldType: "radio",
              name: `customer.${index}.hasInvoice`,
              label: "Igen",
              value: "true"
            },
            {
              fieldType: "radio",
              name: `customer.${index}.hasInvoice`,
              label: "Nem",
              value: "false"
            }
          ]
        ]}
      />
      <Typography variant={"h6"} style={{ paddingTop: "16px" }}>
        Mi a fizetési tapasztalat a vevővel?*
      </Typography>
      <Fields
        config={[
          [
            {
              fieldType: "radio",
              name: `customer.${index}.paymentExperience`,
              label: "Lejárat előtt fizet",
              value: "before"
            }
          ],
          [
            {
              fieldType: "radio",
              name: `customer.${index}.paymentExperience`,
              label: "Lejárat napján fizet",
              value: "due_date"
            }
          ],
          [
            {
              fieldType: "radio",
              name: `customer.${index}.paymentExperience`,
              label: "Kis késéssel fizet",
              value: "little_late"
            }
          ],
          [
            {
              fieldType: "radio",
              name: `customer.${index}.paymentExperience`,
              label: "30 napos késedelmen belül fizet",
              value: "within_30_days_late"
            }
          ],
          [
            {
              fieldType: "radio",
              name: `customer.${index}.paymentExperience`,
              label: "30 napos késedelmen túl fizet",
              value: "over_30_days_late"
            }
          ]
        ]}
      />
      <Typography variant={"h6"} style={{ paddingTop: "16px" }}>
        Volt-e 60 napon túli késedelmes fizetés?*
      </Typography>
      <Fields
        config={[
          [
            {
              fieldType: "radio",
              name: `customer.${index}.latePayment`,
              label: "Igen",
              value: "true"
            },
            {
              fieldType: "radio",
              name: `customer.${index}.latePayment`,
              label: "Nem",
              value: "false"
            }
          ],
          [
            {
              fieldType: "text",
              name: `customer.${index}.latePaymentReason`,
              label: "Ha igen, miért?*",
              disabled: values?.customer?.[index]?.latePayment !== "true"
            }
          ]
        ]}
      />
      <Typography variant={"h6"} style={{ paddingTop: "16px" }}>
        Van beszámítás / levonás a vevővel kötött szerződés szerint?*
      </Typography>
      <div style={{ position: "relative", marginBottom: "12px" }}>
        <Fields
          config={[
            [
              {
                fieldType: "radio",
                name: `customer.${index}.hasCompensation`,
                label: "Igen",
                value: "true"
              },
              {
                fieldType: "radio",
                name: `customer.${index}.hasCompensation`,
                label: "Nem",
                value: "false"
              }
            ],
            [
              {
                fieldType: "checkbox",
                name: `customer.${index}.acceptTerms`,
                required: true,
                label:
                  "Büntetőjogi felelősségem tudatában szavatosságot vállalok az adatok és dokumentumok valódiságáért."
              }
            ]
          ]}
        />
      </div>
    </>
  );
};

const FormDialog = ({ onClose, open, onSubmit, agreementClaim, index }) => {
  const [step, setStep] = useState(0);
  return (
    <FlexiDialog
      open={open}
      title={
        step === 1 ? (
          "Köszönjük, a saját adataid megadásával már készen is vagy! A következő lap/lapok a vevődre/vevőidre vonatkoznak, kérlek a rájuk vonatkozó adatokkal töltsd ki a következő részt."
        ) : step === 0 ? (
          <Typography variant={"h5"}>
            Online adatlap - {agreementClaim?.supplier?.party?.partyName}
          </Typography>
        ) : (
          <div style={{ display: "inline-flex" }}>
            <Typography variant={"h5"}>
              Online adatlap -{" "}
              {agreementClaim.customers[step - 2]?.party?.partyName}
            </Typography>
            <StyledChip
              key={agreementClaim.customers[step - 2]?.calculation?.currency}
              label={agreementClaim.customers[step - 2]?.calculation?.currency}
            />
          </div>
        )
      }
      onClose={onClose}
    >
      {step === 1 ? (
        <Grid container>
          <Grid item xs={12} style={{ display: "inline-flex" }}>
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => setStep(step + 1)}
            >
              Mentés és tovább
            </Button>
            <Button variant={"text"} onClick={() => setStep(step - 1)}>
              Vissza
            </Button>
          </Grid>
        </Grid>
      ) : (
        <FlexiForm
          FinalFormFormProps={{
            onSubmit
          }}
          render={({ handleSubmit, values }) => {
            return (
              <form id={"FactorSheetForm"} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {agreementClaim?.customers.map((customer, index) => (
                      <>
                        {step === index + 2 && (
                          <CustomerSection
                            customer={customer}
                            index={index}
                            values={values}
                          />
                        )}
                      </>
                    ))}
                    {step === 0 && (
                      <>
                        <Fields
                          config={[
                            [
                              {
                                //szerződéstől függően
                                fieldType: "text",
                                name: `supplier.bankAccountHuf`,
                                label: "Bankszámlaszám(HUF)",
                                required: true,
                                disabled: !agreementClaim?.customers.some(
                                  customer =>
                                    customer.calculation?.currency === "HUF"
                                )
                              }
                            ],
                            [
                              {
                                fieldType: "text",
                                name: `supplier.bankAccountEur`,
                                label: "Bankszámlaszám(EUR)",
                                required: true,
                                disabled: !agreementClaim?.customers.some(
                                  customer =>
                                    customer.calculation?.currency === "EUR"
                                )
                              }
                            ],
                            [
                              {
                                fieldType: "text",
                                name: `supplier.bankAccountUsd`,
                                label: "Bankszámlaszám(USD)",
                                required: true,
                                disabled: !agreementClaim?.customers.some(
                                  customer =>
                                    customer.calculation?.currency === "USD"
                                )
                              }
                            ],
                            [
                              {
                                //tömbbe kéne
                                fieldType: "email",
                                name: `supplier.electronicMail`,
                                label:
                                  "Milyen e-mail címre kéred az értesítéseket?"
                              }
                            ]
                          ]}
                        />
                        <Typography
                          variant={"h6"}
                          style={{ paddingTop: "16px" }}
                        >
                          Van-e lejárt adótartozásod?*
                        </Typography>
                        <Fields
                          config={[
                            [
                              {
                                fieldType: "radio",
                                name: `supplier.overdueTaxDebt`,
                                label: "Igen",
                                value: "true"
                              },
                              {
                                fieldType: "radio",
                                name: `supplier.overdueTaxDebt`,
                                label: "Nem",
                                value: "null"
                              }
                            ],
                            [
                              {
                                fieldType: "text",
                                name: `supplier.overdueTaxDebtAmount`,
                                label: "Ha igen, mennyi?*",
                                disabled:
                                  values?.supplier?.overdueTaxDebt !== "true"
                              }
                            ]
                          ]}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ display: "inline-flex" }}>
                    {step !== agreementClaim.customers.length + 1 && (
                      <Button onClick={() => setStep(step + 1)}>
                        Mentés és tovább
                      </Button>
                    )}
                    {step !== agreementClaim.customers.length + 1 &&
                      step !== 0 && (
                        <Button
                          variant={"text"}
                          onClick={() => setStep(step - 1)}
                        >
                          Vissza
                        </Button>
                      )}

                    {step === agreementClaim.customers.length + 1 && (
                      <>
                        <Button style={{ marginRight: "8px" }} type={"submit"}>
                          Mentés
                        </Button>
                        <Button
                          variant={"text"}
                          onClick={() => setStep(step - 1)}
                        >
                          Vissza
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      )}
    </FlexiDialog>
  );
};
export default FormDialog;
